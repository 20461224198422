import { curationService, challengeService } from '../services';
import { curationConstants } from '../constants';

export const curationActions = {
  toggleFilter,
  setFilters,
  clearFilters,
  clearFiltersNotStatus,
  postPortfolioLinkedIdea,
  loadChallengeFilter,
  loadAllStatus,
  getChallenges,
  clearFilter,
  loadIdeas,
  changeCurationProp,
  changeClassification,
  setStatusFixed,
  loadIdeasAdmin,
  changeSearch,
  clearText,
  selectAllFilters
};

function setFilters(name, value) {
  return dispatch => {
    dispatch({ type: curationConstants.SET_CURATION_FILTERS, payload: { name, value } })
  }
}

function selectAllFilters(name) {
  return dispatch => {
    dispatch({ type: curationConstants.SELECT_ALL_FILTERS, payload: { name } })
  }
}

function setStatusFixed(status) {
  return dispatch => {
    dispatch({ type: curationConstants.SET_STATUS_FIXED, payload: status })
  }
}

function changeClassification(name, value) {
  return dispatch => {
    dispatch({ type: curationConstants.CHANGE_CURATION_CLASSIFICATION, payload: { name, value } })
  }
}

function clearFilters() {
  return dispatch => dispatch({ type: curationConstants.CLEAR_CURATION_FILTERS });
}

function clearFiltersNotStatus() {
  return dispatch => dispatch({ type: curationConstants.CLEAR_FILTERS_NOT_STATUS });

}

function toggleFilter(filter) {
  return (dispatch) => {
    dispatch(success());

    function success() {
      const payload = {
        filter,
      };
      return { type: curationConstants.TOGGLE_FILTER, payload };
    }
  };
}

function postPortfolioLinkedIdea(data) {
  return dispatch => {
    dispatch({ type: curationConstants.GET_ALL_IDEAS_PORTFOLIO_REQUEST });
    curationService.postPortfolioLinkedIdea(data).then((ideas) => {
      dispatch({
        type: curationConstants.GET_ALL_IDEAS_PORTFOLIO_SUCCESS,
        payload: ideas
      });
    },
      (error) => {
        dispatch({
          type: curationConstants.GET_ALL_IDEAS_PORTFOLIO_FAILURE,
          error
        });
      })
  };
}

function loadChallengeFilter(challenges) {
  return (dispatch) => {
    dispatch({
      type: curationConstants.LOAD_CURATION_CHALLENGES_FILTER,
      payload: challenges,
    })
  }
}

function loadAllStatus(status) {
  const selectAllOption = {
    label: 'Selecionar todos',
    value: 'SELECT_ALL',
    checked: true
  };
  return (dispatch) => {
    dispatch({
      type: curationConstants.LOAD_CURATION_STATUS_FILTER,
      payload: [selectAllOption, ...status],
    })
  }
}

function getChallenges(page, sort, filter) {
  return dispatch => {
    dispatch({
      type: curationConstants.LOAD_CURATION_CHALLENGE_FILTER_REQUEST,
    })

    challengeService.getChallenges(page, sort, filter)
      .then((response) => {
        const challenges = response.data.map(item => ({
          label: item.name,
          value: item._id,
          checked: false
        }))

        const selectAllOption = {
          label: 'Selecionar todos',
          value: 'SELECT_ALL',
          checked: false
        };

        dispatch({
          type: curationConstants.LOAD_CURATION_CHALLENGE_FILTER_SUCCESS,
          payload: [selectAllOption, ...challenges]
        })
      })
      .catch((err) => {

      })
  }
}

function clearFilter(name) {
  return dispatch => {
    dispatch({
      type: curationConstants.CLEAR_CURATION_FILTER,
      payload: name
    })
  }
}

function loadIdeas(filters, paging, occupationArea) {
  return dispatch => {
    const filteredStatus = filters.statusCode.filter(item => item.checked);
    const filteredOccupationArea = occupationArea.filter(item => item.checked);
    const filteredChallenges = filters.challenges.filter(item => item.checked);

    dispatch({
      type: curationConstants.GET_IDEAS_CURATION_REQUEST
    });

    curationService.filter({
      ...filters,
      statusCode: filteredStatus.map(item => item.value),
      occupationAreas: filteredOccupationArea.map(item => item.value),
      challenges: filteredChallenges.map(item => item.value),
      page: paging.page,
      limit: paging.limit,
      sort: paging.sort
    })
      .then((response) => {
        dispatch({
          type: curationConstants.GET_IDEAS_CURATION_SUCCESS,
          payload: response
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: curationConstants.GET_IDEAS_CURATION_FAILURE,
        })
      })
  }
}

function loadIdeasAdmin(filters, paging, occupationArea) {
  return dispatch => {
    const filteredStatus = filters.statusCode.filter(item => item.checked);
    const filteredOccupationArea = occupationArea.filter(item => item.checked);
    const filteredChallenges = filters.challenges.filter(item => item.checked);
  
    dispatch({ 
      type: curationConstants.GET_IDEAS_CURATION_REQUEST
    });

    curationService.filterAdmin({
      ...filters,
      statusCode: filteredStatus.map(item => item.value),
      occupationAreas: filteredOccupationArea.map(item => item.value),
      challenges: filteredChallenges.map(item => item.value),
      page: paging.page,
      limit: paging.limit,
      sort: paging.sort
    })
      .then((response) => {
        dispatch({
          type: curationConstants.GET_IDEAS_CURATION_SUCCESS, 
          payload: response
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: curationConstants.GET_IDEAS_CURATION_FAILURE, 
        })
      })
  }
}

function changeCurationProp(name, value) {
  return dispatch => {
    dispatch({
      type: curationConstants.CHANGE_CURATION_PROP,
      payload: { name, value }
    })
  }
}

function changeSearch(text) {
  return dispatch => {
    dispatch({
      type: curationConstants.CHANGE_CURATON_SEARCH,
      payload: text
    })
  }
}

function clearText() {
  return dispatch => {
    dispatch({
      type: curationConstants.CLEAR_CURATION_TEXT_SEARCH
    })
  }
}