export function authHeader() {
  const token = JSON.parse(localStorage.getItem('token'));
  const language = localStorage.getItem('i18nextLng') || 'es';

  if (token && token.access_token) {
    return {
      Authorization: 'Bearer ' + token.access_token,
      language: language,
    };
  } else {
    return {};
  }
}
