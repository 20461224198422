import { Container, Grid, Hidden } from '@material-ui/core';
import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BackNavAdmin, CustomSelect, SidebarManageAccess } from '../../components';
import { optionConstants } from '../../constants';
import { AdminUserEditPage } from '../AdminUserEditPage';
import { UserFunctionPage } from '../UserFunctionPage';
import { UserPoints } from '../UserPoints';

function AdminUserEditLayout() {
  const { t } = useTranslation();
  const [type, setType] = useState('atribuir-funcao');
  const { id } = useParams();

  const title = useMemo(() => {
    switch (type) {
      case 'atribuir-funcao':
        return 'atribuir_funcao_ao_usuario';
      case 'editar-dados':
        return 'editar_dados_do_usuario';
      case 'gerenciar-pontos':
        return 'gerenciar_pontos';
      default:
        return '';
    }
  }, [type]);

  return (
    <>
      <BackNavAdmin title={t(title)} />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
        <Hidden mdUp>
          <Grid item xs={12}>
                <CustomSelect
                optionsAvaiable={[
                  optionConstants.ATRIBUIR_FUNCAO_AO_USUARIO,
                  optionConstants.EDITAR_DADOS_DE_USUARIO,
                  optionConstants.GERENCIAR_PONTOS,
                ]}
                startOption={optionConstants.ATRIBUIR_FUNCAO_AO_USUARIO}
                handlePage={setType}
              />
          </Grid>
        </Hidden>
          <Hidden smDown>
            <Grid style={{ marginTop: 45 }} item xs={3} spacing={3}>
              <SidebarManageAccess type={type} setType={setType} />
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={9}>
            {type === 'atribuir-funcao' && <UserFunctionPage id={id} />}
            {type === 'editar-dados' && <AdminUserEditPage id={id} />}
            {type === 'gerenciar-pontos' && <UserPoints id={id} />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export { AdminUserEditLayout };
