import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import {
  Box,
  Card, 
  List, 
  Divider,
  ListItem, 
  ListItemText,
  Typography, 
  makeStyles,
} from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';

import { contentsActions } from '../../actions';
import { truncate, history } from '../../helpers';
import { CustomTooltip } from '../Shared/CustomTooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  cardTitle: {
    color: theme.palette.neutrals.low.main,
  },
  icon: {
    marginRight: 6,
    color: theme.palette.auxiliary.pink.main,
  },
  list: {
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    backgroundColor: theme.palette.neutrals.high.main
  },
  title: {
    cursor: 'pointer',
    wordWrap: 'break-word',
    color: theme.palette.neutrals.low.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  description: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: theme.spacing(1),
    color: theme.palette.neutrals.low.light,    
  },
  seeAll: {
    fontWeight: 600,
    fontSize: 11,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  cardSeeAll: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  textSeeAll: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  }
}));

export function Contents({ title }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { contents, contentsLoading } = useSelector(state => state.contents)

  useEffect(() => {
    dispatch(contentsActions.getContents());
  }, [dispatch])

  function handleSeeAllContent() {
    history.push('/contents-details')
  }

  return (
    <div className={classes.root}>
      {title && (
        <Card className={classes.cardSeeAll} elevation={0}>
          <Box display="flex" alignItems="center">
            <NoteIcon className={classes.icon} />
            <Typography variant="subtitle2" className={classes.cardTitle}>
              {t('conteudos')}
            </Typography>
          </Box>
          <Typography className={classes.textSeeAll} onClick={handleSeeAllContent}>
            {t('ver_todos')}
          </Typography>
        </Card>
      )}
      {!contentsLoading && (
        <List disablePadding className={classes.list}>
         { contents &&
           contents.slice(0, 4).map((content, index) => {
            return content.highlight && (
              <>
                <ListItem disableGutters key={index}>
                  <ListItemText>
                    <CustomTooltip title={content.title}>
                      {content.link ? (
                        <Typography variant="subtitle2">
                          <a
                            className={classes.title}
                            href={content.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ wordWrap: 'break-word' }} 
                          >
                            {content.title}
                          </a>
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2">
                          {content.title}
                        </Typography>
                      )}
                    </CustomTooltip>
                    {/* <div className={classes.subTitle}>
                      <TimeAgo
                        date={content.createdAt}
                        formatter={formatterTimer}
                      />{' '}
                      por {content.user.name}
                    </div> */}
                    <Typography className={classes.description}>
                      {truncate(content.description, 120)}
                    </Typography>
                  </ListItemText>
                </ListItem>
                {index !== (contents.length - 1) && <Divider className={classes.divider} />}
              </>
            );
          })}
        </List>
      )}
      {/*  <div className={classes.seeAll}>VER TODOS</div> */}
    </div>
  );
}