import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Button,
  Avatar,
  Typography
} from "@material-ui/core"
import { ideaEditStyles } from './IdeaEditStyles'
import Info from '@material-ui/icons/Info';
import dialogVideoSvg from '../../assets/imgs/dialog-video.svg';
import { EditUpload, EditUploadButton } from './../../components/Upload';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import VideocamIcon from '@material-ui/icons/Videocam';

export const IdeaEditGeneralVideo = () => {
  const classes = ideaEditStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Avatar className={classes.avatarIcon}>
              <VideocamIcon color="primary" />
            </Avatar>
            <Typography variant="h3">{t('videos')}</Typography>
          </Box>
          <EditUploadButton 
            type="video"
            variant="text"
            text={t('adicionar_video')}
            icon={<AddCircleOutlinedIcon />}
          />
        </Grid>
        <Grid item xs={12}>
        <EditUpload
            type="video"
            img={dialogVideoSvg}
            name="video"
            accept="video/*"
            message=""
            maxSize={52428800}
          />
          <Box display="flex" alignItems="center" className={classes.info}>
            <Info style={{fontSize: 15, marginRight: 7 }}/>
            <Typography variant="caption">
              {t('o_arquivo_deve_possuir_tamanho_maximo_de')}{' '}<strong>50mb</strong>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
