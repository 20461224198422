import React, { useState } from 'react';
import {
  Card,
  CardActions,
  Button,
  Collapse,
  CardContent,
  makeStyles,
  CardMedia,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  Box,
} from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { challengeActions } from '../../actions';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import dialogPhotoSvg from '../../assets/imgs/illustration-image-drag-n-drop.svg';
import { ReactComponent as PhotoIcon } from '../../assets/imgs/filled-image_24dp.svg';
import InfoIcon from '@material-ui/icons/Info';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import infoSvg from '../../assets/imgs/info.svg';
import { useTranslation } from 'react-i18next';
import { ImageCrop } from '../ImageCrop';

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  content: {
    padding: theme.spacing(2, 10),
  },
  contenteCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageChildren: {
    padding: '60px 80px',
    backgroundColor: theme.palette.neutrals.high.lightest,
    textAlign: 'center',
    fontSize: 16,
  },
  textImage: {
    color: theme.palette.primary.main,
  },
  successDropZone: {
    color: theme.palette.primary.main,
    fontSize: 30,
    fontWeight: 600,
  },
  errorDropZone: {
    color: theme.palette.error.main,
    fontSize: 30,
    fontWeight: 600,
  },
  info: {
    padding: theme.spacing(6),
    fontSize: 13,
    display: 'flex',
    color: theme.palette.neutrals.low.medium,
  },
  infoBold: {
    fontWeight: 600,
  },
  message: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    top: -16,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `3px dashed ${theme.palette.primary.light}`,
    borderRadius: 10,
  },
  media: {
    width: '100%',
    paddingTop: ({type}) => type === 'thumbnail' ? '56.25%' : '18.75%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  iconRemove: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  dialogTitle: {
    fontSize: 20,
    color: 'dialogTitle',
    fontWeight: 'bold',
    padding: theme.spacing(2, 0),
  },
  dialogDescription: {
    fontSize: 14,
    color: theme.palette.neutrals.low.medium,
  },
  dialogActions: {
    padding: theme.spacing(4, 0),
    justifyContent: 'center',
  },
  thumbTitle: { padding: '0 40px' }
}));

function ChallengeImageCard({ type, imagem, id, isEdit=false }) {
  const dispatch = useDispatch();
  const classes = useStyles({type});
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null)
  const { t } = useTranslation();
  const theme = useTheme();
  const aspectRatio = type === 'thumbnail' ? 16/9 : 16/3;

  function handleClickOpenDialog() {
    setOpenDialog(true);
  }

  function handleImageCrop(blob) {    
   dispatch(challengeActions.setImagem(type, {
      ...file,
      file: new File([blob], file.name,{
        type: blob.type,
      }),
      readableSize: filesize(blob.size),
      preview: URL.createObjectURL(blob),
      type: blob.type,
    }));
    onSubmit();
  };

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function removeImage() {
    if (isEdit) {
      dispatch(challengeActions.removeImage(type));
      dispatch(challengeActions.setImageToRemove(type));
    } else {
      dispatch(challengeActions.removeImage(type));
    }
  }

  function renderDragMessage(isDragActive, isDragReject) {
    if (!isDragActive) {
      return <Box></Box>;
    }

    if (isDragReject) {
      handleClickOpenDialog();
      return;
    }

    return (
      <Box className={classes.message}>
        <Box className={classes.successDropZone}> {t('solte_a_imagem_aqui')}</Box>
      </Box>
    );
  }

  function onUpload(archives) {
    const newUploadedFiles = archives.map((file) => ({
      id: uniqueId(),
      name: file.name,
      preview: URL.createObjectURL(file),
    }));
    setFile({ id: newUploadedFiles[0].id, name: newUploadedFiles[0].name })
    setSrc(newUploadedFiles[0].preview);
    setOpen(true);
  }

  function closeCropper() {
    setOpen(false);
  }

  function onSubmit() {
    dispatch(challengeActions.imageNextCard());
  }

  return (
    <Box>
 {type === 'thumbnail' && (<CardActions className={classes.actions}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box className={classes.iconDiscription}>
            <AddAPhotoIcon color="primary" />
          </Box>
          <Box className={classes.description}>{t('imagem')}</Box>
        </Box>
      </CardActions>)}
        <b className={classes.thumbTitle}>{type === 'thumbnail' ? t('miniatura_do_card') : t('miniatura_do_banner') }</b>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogContent>
            <Box className={classes.dialogHeader}>
              <IconButton onClick={handleCloseDialog}>
                <CloseIcon style={{ color: theme.palette.neutrals.low.medium }} />
              </IconButton>
            </Box>
            <DialogContentText className={classes.dialogContent}>
              <Box>
                <img src={infoSvg} alt="info" />
              </Box>
              <Box className={classes.dialogTitle}>
                {t('ops')}! {t('aconteceu_um_erro')}
              </Box>
              <Box className={classes.dialogDescription}>
                {t('nao_foi_possivel_fazer_o_upload')}.{' '}
                {t(
                  'tipo_de_arquivo_nao_suportado_ou_execedeu_o_tamanho_maximo'
                )}
                .
              </Box>
            </DialogContentText>
            <DialogActions className={classes.dialogActions}>
              <Button
                onClick={handleCloseDialog}
                color="primary"
                variant="contained"
              >
                {t('ok')}, {t('entendi')}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <CardContent className={classes.content}>
          <Box className={classes.contenteCenter}>
            <ImageCrop
              src={src}
              dispatch={handleImageCrop}
              open={open}
              close={closeCropper}
              aspect={aspectRatio}
            />
            {imagem && imagem.preview ? (
              <Box style={{width:'100%'}}>
                <CardMedia className={classes.media} image={imagem.preview} />
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <PhotoIcon style={{ marginRight: 10 }} />
                  <Box>
                    {imagem.name} ({imagem.readableSize})
                  </Box>
                  <IconButton onClick={removeImage}>
                    <DeleteIcon className={classes.iconRemove} />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <>
              <Dropzone
                accept="image/png, image/jpeg"
                onDropAccepted={onUpload}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                }) => (
                  <Box style={{ position: 'relative' }}>
                    <Box {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Box className={classes.imageChildren}>
                      <Box
                        style={{
                          cursor: 'pointer',
                          outline: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Box style={{ height: 164 }}>
                          <img
                            style={{ height: 164 }}
                            src={dialogPhotoSvg}
                            alt="photoSvg"
                          />
                        </Box>
                          {renderDragMessage(isDragActive, isDragReject)}
                          {t('arraste_seu_arquivo_aqui')} {t('ou')} <br />{' '}
                          <span className={classes.textImage}>
                            {t('procure_em_seu_computador')}
                          </span>
                        </Box>
                      </Box>
                      <Box className={classes.info}>
                        <Box style={{ marginRight: 10 }}>
                          <InfoIcon style={{ color: theme.palette.neutrals.low.lightest }} />
                        </Box>
                        <Box>
                          {t('o_arquivo_deve_ser_em_formato')}{' '}
                          <span className={classes.infoBold}>png</span> {t('e')}{' '}
                          <span className={classes.infoBold}>jpg</span> {t('e')}{' '}
                          {t('possuir_tamanho_maximo_de')}{' '}
                          <span className={classes.infoBold}>5mb</span>. <br />{' '}
                          {t('a_resolucao')}{' '}
                          <span className={classes.infoBold}>
                            {t('recomendada')}
                          </span>{' '}
                          {t('eh_de')}{' '}{t('no_minimo')}{' '}
                          <span className={classes.infoBold}>{type === 'thumbnail' ? '1280 x 720' : '1280 x 240'}</span>{' '}
                          pixels
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Dropzone>
              </>
            )}
          </Box>
        </CardContent>
    </Box>
  );
}

export { ChallengeImageCard };
