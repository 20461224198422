/* eslint-disable no-duplicate-case */
import { reportConstants } from "./../constants"
const today = new Date();
const beforeMonth = new Date().setMonth(new Date().getMonth() - 1);

const initialState = {
  filters: {
    status: [],
    challenges: [],
    properties: [],
    startDate: beforeMonth,
    endDate: today,
    query: "",
    ideasUngrouped: false,
    ideaDescriptionOnCsv: false,
    useConclusionDate: false,
  },
  tab: 'table',
  report: [],
  chart: [],
  paging: {
    limit: 10,
    page: 1,
    skip: 0,
    query: '',
    sort: {
      createdAt: -1
    },
    total: 0
  },
  fieldValidation: {
    "ideas-status": {
      businessUnit: false,
      local: true,
      area: true,
      status: true,
      startDate: true,
      endDate: true,
      challenges: true,
    },
    "ideas-status-pending-managers": {
      businessUnit: false,
      local: false,
      area: false,
      status: false,
      startDate: true,
      endDate: true,
    },
    "colaborator": {
      businessUnit: false,
      local: true,
      area: true,
      status: false,
      startDate: true,
      endDate: true,
    },
    "ideas-financial-return": {
      businessUnit: false,
      local: true,
      area: true,
      status: true,
      startDate: true,
      endDate: true,
    },
    "colaborator-access": {
      businessUnit: true,
      local: true,
      area: true,
      status: false,
      startDate: true,
      endDate: true,
    },
    "managers-list": {
      local: true,
      area: true,
      businessUnit: true,
      status: false,
      startDate: false,
      endDate: false,
      showCheckBoxEmptyBul: true
    }
  },
  loading: false,
  csvLoading: false,
  csvBase64: "",
}

export const report = (state = initialState, action) => {
  switch (action.type) {
    case reportConstants.LOAD_PROPERTIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          properties: action.payload,
        }
      }

    case reportConstants.CHANGE_PROPERTIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          properties: state.filters.properties.map((item, index) => {
            if (index === parseInt(action.payload.index)) {
              return {
                ...item,
                checked: !item.checked
              }
            }

            return item;
          })
        }
      }

    case reportConstants.LOAD_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload,
        }
      }

    case reportConstants.REPORTS_LOAD_CHALLENGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          challenges: action.payload,
        }
      }

    case reportConstants.CHANGE_STATUS:
      if (action.payload.value === 'SELECT_ALL') {
        return {
          ...state,
          filters: {
            ...state.filters,
            status: state.filters.status.map(item => ({
              ...item,
              checked: action.payload.checked ? false : true
            }))
          }
        }
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            status: state.filters.status.map(item => {
              if (item.value === action.payload.value) {
                return {
                  ...item,
                  checked: !action.payload.checked
                }
              }
              return item;
            })
          }
        }
      }

    case reportConstants.REPORTS_CHANGE_CHALLENGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          challenges: state.filters.challenges.map(item => {
            if (item.value === action.payload.value) {
              return {
                ...item,
                checked: !action.payload.checked
              }
            }

            return item;
          })
        }
      }

    case reportConstants.CHANGE_START_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload
        }
      }

    case reportConstants.CHANGE_END_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          endDate: action.payload
        }
      }

    case reportConstants.CHANGE_BUL_EMPTY_CHECKED:
      return {
        ...state,
        filters: {
          ...state.filters,
          bulWithoutUsers: action.payload
        }
      };
    
    case reportConstants.SHOW_IDEAS_UNGROUPED:
    case reportConstants.SHOW_IDEA_DESCRIPTION_ON_CSV:
    case reportConstants.USE_CONCLUSION_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.checked
        }
      }

    case reportConstants.PRE_REPORT_DATA_REQUEST: {
      return {
        ...state,
        report: [],
        chart: [],
      }
    }

    case reportConstants.REPORT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case reportConstants.REPORT_DATA_SUCCESS:
      return {
        ...state,
        paging: action.payload.paging,
        report: action.payload.report,
        csvBase64: false,
        loading: false,
      }

    case reportConstants.REPORT_DATA_CHART_SUCCESS:
      return {
        ...state,
        chart: action.payload,
        loading: false,
      }

    case reportConstants.REPORT_DATA_FAILURE:
      return {
        ...state,
        loading: false
      }

    case reportConstants.CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
          challenges: state.filters.challenges,
          status: state.filters.status,
          startDate: beforeMonth,
          endDate: today,
          ideasUngrouped: initialState.filters.ideasUngrouped,
          ideaDescriptionOnCsv: initialState.filters.ideaDescriptionOnCsv,
          useConclusionDate: initialState.filters.useConclusionDate,
        }
      };

    case reportConstants.CLEAR_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: state.filters.status.map(item => ({
            ...item,
            checked: false,
          }))
        }
      }

    case reportConstants.REPORTS_CLEAR_CHALLENGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          challenges: state.filters.challenges.map(item => ({
            ...item,
            checked: false,
          }))
        }
      }

    case reportConstants.CLEAR_ALL_REPORT:
      return initialState

    case reportConstants.GET_REPORT_CSV_REQUEST:
      return {
        ...state,
        csvLoading: true
      }

    case reportConstants.GET_REPORT_CSV_SUCCESS:
      return {
        ...state,
        csvLoading: false,
        csvBase64: action.payload
      }

    case reportConstants.GET_REPORT_CSV_FAILURE:
      return {
        ...state,
        csvLoading: false,
        error: "Não foi possível gerar CSV"
      }

    case reportConstants.SER_REPORT_TAB:
      return {
        ...state,
        tab: action.payload
      }

    case reportConstants.CLEAR_REPORT_SEARCH: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: ''
        }
      }
    }
    case reportConstants.CHANGE_REPORT_SEARCH: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload
        }
      }
    }
    default:
      return state;
  }
}