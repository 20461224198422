import { makeStyles } from '@material-ui/core';

export const menuCss = makeStyles((theme) => ({
  // Navbar
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    zIndex: 1300,
  },
  container: {
    padding: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  logo: {
    width: 130,
    height: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  listDrawer: {
    width: 256,
    marginTop: 68,
  },
  searchInputWrapper: {
    maxWidth: 300
  },
  searchInput: {
    color: theme.palette.neutrals.high.main,
  },
  navbar: { 
    backgroundColor: theme.palette.primary.main 
  },
  searchInputMobile: {  
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "4px",
    padding: "0.5rem",
    color: "#fff",
    height: "52px",
  },
  searchInputIconButton: {
    padding: 10,
  },
  dividerMobile: {
    height: 28,
    background: "#fff",
    margin: 4,
  },

  // MenuAdmin || MenuColaborator || MenuManager
  list: {
    padding: theme.spacing(2),
  },
  listItemIcon: {
    minWidth: 36,
  },
  listItem: {
    width: 224,
    padding: theme.spacing(1, 3),
    marginBottom: theme.spacing(1)
  },
  subItem: {
    width: 224,
    padding: theme.spacing(1, 2, 1, 8),
  },
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightest,
  },
  typography: {
    fontSize: 14,
    fontWeight: 600,
  },
  colorActived: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  },
  colorDisabled: {
    color: theme.palette.neutrals.low.medium,
    fill: theme.palette.neutrals.low.medium
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  badgeColor: {
    color: theme.palette.neutrals.high.main,
    backgroundColor: theme.palette.auxiliary.pink.main
  }
}));