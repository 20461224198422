import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { 
  Card,
  Grid,
  makeStyles, 
  Avatar, 
  Typography,
  Chip
} from '@material-ui/core';

import { AvatarWithoutPhoto } from '../Shared/AvatarWithoutPhoto';
import { history } from '../../helpers';

const useStyle = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  gridFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    cursor: 'pointer'
  },
  position: {
    color: theme.palette.grey.darkest,
  },
  perfil: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    borderRadius: 4
  },
}));

function FeedIdentity() {
  const classes = useStyle();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  function goToProfile() {
    history.push(`/profile/${user._id}`);
  }

  return (
    <Card className={classes.card} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.gridFlex}>
          {user.attachment?.url ? (
            <Avatar src={user.attachment.url} className={classes.avatar} onClick={goToProfile} />
          ) : (
            <AvatarWithoutPhoto 
              superLarge iconStyle={{ fontSize: 80 }} 
              onClick={goToProfile} 
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" onClick={goToProfile}>
            {user.name}
          </Typography>      
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" align="center" className={classes.position}>
            {user.position}
            {user.businessUnitLocal?.occupationArea?.name && (
              <>{` ${t('em')}`} {user.businessUnitLocal?.occupationArea?.name}</>
            )}
          </Typography>
        </Grid>
        {(user.typeProfile.type === 'manager' || user.typeProfile.type === 'admin') && (
          <Grid item xs={12} className={classes.gridFlex}>
            <Chip label={user.typeProfile.description} color="primary" size="small" className={classes.perfil} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
}

export { FeedIdentity };
