import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex', 
    justifyContent: 'center',
  },
  listItem: {
    width: 'auto',
    '&:hover':{
      background: 'transparent',
    }
  },
  listItemText: {
    color: theme.palette.neutrals.high.main,
    fontWeight: 500,
    fontSize: 15,
  },
  listItemTextActive: {
    color: theme.palette.neutrals.high.main,
    fontWeight: 500,
    fontSize: 15,
  },
}));

function MenuTerms() {
  const classes = useStyles();
  const location = useLocation();
  const authentication = useSelector((state) => state.authentication);
  const user = useSelector((state) => state.user);

  return (
    <List className={classes.root}>
      <ListItem
        button
        component={Link}
        to="/regulation"
        className={classes.listItem}
      >
        <ListItemText
          classes={
            location.pathname === '/regulation'
              ? { primary: classes.listItemTextActive }
              : { primary: classes.listItemText }
          }
        >
          políticas de privacidade
        </ListItemText>
      </ListItem>

      <ListItem
        button
        component={Link}
        to="/terms"
        className={classes.listItem}
      >
        <ListItemText
          classes={
            location.pathname === '/terms'
              ? { primary: classes.listItemTextActive }
              : { primary: classes.listItemText }
          }
        >
          termos de uso
        </ListItemText>
      </ListItem>   
    </List>
  );
}

export { MenuTerms };
