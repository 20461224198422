import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 240,
    maxWidth: 360,

    margin: 12,

    width: '40%',
    paddingTop: 32,
    paddingLeft: 24,
    paddingBottom: 32,
    paddingRight: 24,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:900px)']: {
      width: '30%',
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: 16,
  },

  text: {
    margin: 0,
  },
  body: {
    marginTop: 16,
  },
  phone: {
    margin: 0,
    marginTop: 8,
    color: theme.palette.neutrals.low.medium,

  },
  email: {
    textDecoration: 'none',

  },
  localUnit: {
    marginTop: 16,
    margin: 0,
    color: theme.palette.neutrals.low.medium,

  }
}))


function CardProfile({
  name,
  email,
  phone,
  pictureUrl,
  position }) {

  const classes = useStyles();





  return (
    <Card className={classes.root} >
      <div className={classes.header}>
        <Avatar alt={`${name} photo's`} src={pictureUrl} />
        <div className={classes.headerSide} >
          <strong>{name}</strong>
          <p className={classes.text} >Inovação</p>
        </div>
      </div>
      <div className={classes.body} >
        <a
          href={`mailto:${email}`}
          className={classes.email}
        >{email}</a>
        <p className={classes.phone} >{phone}</p>
        <p className={classes.localUnit} >{position}</p>
      </div>
    </Card>

  );
}
export { CardProfile };
