import React from 'react';
import { Button, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 0.5),
    padding: '10px 16px',
  },
  active: {
    backgroundColor: 'black',
  },
}));

function FilterTable({ onSelectFilter, selecteds }) {
  const classes = useStyles();
  const theme = useTheme();

  function selectFilter(filter) {
    onSelectFilter(filter);
  }

  function selected(filter) {
    return selecteds.includes(filter);
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>visualizar: </div>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => selectFilter('DRAFT')}
        style={
          selected('DRAFT')
            ? { backgroundColor: theme.palette.primary.light, color: theme.palette.primary.main }
            : {}
        }
      >
        rascunho
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => selectFilter('LINE_EXECUTION')}
        style={
          selected('LINE_EXECUTION')
            ? { backgroundColor: theme.palette.primary.light, color: theme.palette.primary.main }
            : {}
        }
      >
        fila de execução
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => selectFilter('NOT_QUALIFICAED')}
        style={
          selected('NOT_QUALIFICAED')
            ? { backgroundColor: theme.palette.primary.light, color: theme.palette.primary.main }
            : {}
        }
      >
        NÃO QUALIFICADA
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => selectFilter('EXECUTING')}
        style={
          selected('EXECUTING')
            ? { backgroundColor: theme.palette.primary.light, color: theme.palette.primary.main }
            : {}
        }
      >
        EM EXECUÇÃO
      </Button>
      {/* <Button variant="outlined" color="primary" className={classes.button}>
        AGUARDANDO QUALIFICAÇÃO
      </Button> */}
    </div>
  );
}

export { FilterTable };
