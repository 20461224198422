import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const collapseCss = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  errorSpan: {
    position: 'absolute',
    width: 550,
    right: -300,
  },
  subtitle: {
    fontSize: 13,
    color: theme.palette.neutrals.low.medium,
    marginTop: 16,
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 2),
    }
  },
  containerRemoveButton: {
    display: 'flex',
    alignItems: 'center',
  },
  removeButton: {
    color: grey[500],
    '&:hover': {
      color: theme.palette.auxiliary.pink.main,
    },
  },
  card: {
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.neutrals.high.medium
  }
}));
