import React, { useEffect, Fragment } from 'react'
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { 
  BackNavAdmin,
  SidebarManageAccess,
  Search,
  UsersPointsTable,
  SelectedUser,
  UserPointsFilterMenu,
} from "./../../components";
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from 'react-i18next';
import { userFunctionActions, walletActions } from "./../../actions"
import { history, searchToObject } from "./../../helpers"
import { useParams } from "react-router-dom";
import { SkeletonTable } from '../../components/Skeletons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
  container: {
    marginTop: 32
  }
}))

export const UserPoints = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    selectedUser,
    loadingUser,
  } = useSelector(state => state.userFunction)
  const { imagineLocals } = useSelector(state => state.domains)
  const {
    paging,
    loading,
    paging: { quantityPerPage, filter, sort, query },
  } = useSelector((state) => state.wallet);
  const { _id } = useSelector((state) => state.user);

  useEffect(() => {
    if (!selectedUser && !loadingUser) {
      if (id) {
        dispatch(userFunctionActions.loadPage(id, imagineLocals));
      } else {
        history.push("/users");
      }
    } else {
      if (selectedUser) {
        dispatch(walletActions.getUserPoints(selectedUser.id));
        dispatch(walletActions.getWallets(selectedUser.id, 1, quantityPerPage, filter, sort, query))
      }
    }
  }, [selectedUser, id])

  useEffect(() => {
    return () => dispatch(walletActions.clearState())
  }, [dispatch])

  function searchWallet(q) {
    dispatch(walletActions.setQuery(q));
    dispatch(walletActions.getWallets(_id, 1, quantityPerPage, filter, sort, q));
  }

  return (
        <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              { selectedUser && (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <SelectedUser showPoints={true} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={7}>
                        <UserPointsFilterMenu />
                      </Grid>
                      <Grid item xs={5}>
                        <Search
                          disabled={loading}
                          placeholder={"pesquisar por ideia ou ação"}
                          query={searchWallet}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {loading ? <SkeletonTable cols={4} /> : <UsersPointsTable />}
                  </Grid>
                </Grid>
              ) }
            </Grid>
        </Grid>
  )
}