import { ideaConclusionConstanst } from '../constants';

const initialState = {
    startDate: null,
    conclusionDate: null,
    financialReturn: null,
    conclusionDetails: '',
    costs: [],
  loadingCancelIdea: false,
  loadingCancelIdeaSuccess: false,
  loadingCancelIdeaFailure: false,
};

export function ideaConclusion(state = initialState, action) {
  switch (action.type) {




    case ideaConclusionConstanst.SET_CONCLUSION_INPUT: {
      return {

        ...state,
        [action.payload.name]: action.payload.value,
    }

    }

    case ideaConclusionConstanst.SET_CONCLUSION_COSTS: {
      return {
        ...state,
        costs: action.payload.map((item) => ({
          costDescription: item.costDescription,
          estimatedCost: !item.estimatedCost ? 0 : parseFloat(item.estimatedCost),
          cost: parseFloat(item.cost),
        }))

      }
    }

    case ideaConclusionConstanst.PUT_CONCLUSION_IDEA_SUCCESS: {
      return initialState;
    }
    case ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_REQUEST: {
      return {
        ...state,
        loadingCancelIdea: true
      }
    }
    case ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_FAILURE: {
      return {
        ...state,
        loadingCancelIdea: false,
        loadingCancelIdeaFailure: true
      }
    }

    case ideaConclusionConstanst.PUT_CANCEL_CONCLUSION_IDEA_SUCCESS: {
      return initialState;
    }

    case ideaConclusionConstanst.IDEA_CONCLUSION_CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}
