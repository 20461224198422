import React from 'react'
import { makeStyles } from "@material-ui/core"

export const ImagineGrid = ({ 
  container=false, 
  item=false, 
  spacing=0, 
  cols=1,
  xs,
  sm,
  md,
  lg,
  start,
  end,
  className,
  children,
  onClick = () => {},
}) => {
  const useStyle = makeStyles((theme) => ({
    gridContainer: {
      display: "grid",
      gridTemplateColumns:`repeat(${cols},minmax(0,1fr))`,
      gap: `${spacing * 0.25}rem`,
      width: "100%",
    },
    gridItem: {
      [theme.breakpoints.down('sm')]: {
        gridColumn: `span ${sm || xs || md || lg || cols} / span ${sm || xs || md || lg || cols}`,
      },
      [theme.breakpoints.up('md')]: {
        gridColumn: `span ${md || lg || sm || xs || cols} / span ${md || lg || sm || xs || cols}`,
      },
      [theme.breakpoints.up('lg')]: {
        gridColumn: `span ${lg || md || sm || xs || cols} / span ${lg || md || sm || xs || cols}`,
      },
    },
  }));

  const classes = useStyle();

  return (
    <div className={`${container ? classes.gridContainer : ''} ${item ? classes.gridItem : ''} ${className}`} onClick={onClick}>
      { children }
    </div>
  )
}
