import React, { useState } from 'react';
import TimeAgo from 'react-timeago';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { formatterTimer } from '../../../helpers';
import { CustomTooltip } from '../CustomTooltip';
import { 
  Avatar, 
  CustomAvatar, 
  Name, 
  Date, 
  Participants,
  Modal
} from './styles';

export function AvatarGroupIdea({ user, idea }) {
  const { t } = useTranslation();
  const [viewParticipants, setViewParticipants] = useState(null);
  const language = useSelector((state) => state.language);

  function handleViewParticipants(e) {
    setViewParticipants(e.currentTarget);
  };

  function handleCloseMenu() {
    setViewParticipants(null);
  };

  function getFirstName(firstName) {
    const arrName = firstName.split(' ');
    if (arrName.length > 1) {
      return arrName[0];
    }
    return arrName;
  }

  return (
    <>
      <Modal
        keepMounted
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={viewParticipants}
        open={Boolean(viewParticipants)}
        onClose={handleCloseMenu}
      >
        <Grid container spacing={2} style={{ width: 'auto', display: 'block' }}>
          {idea.contributors.filter((item, index) => index !== 0).map((item, index) => (
            <Grid item xs={12} key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={item.attachment?.url} alt={item.name} style={{ width: 30, height: 30 }} />
              <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                {item.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Modal>

      <Box display="flex" alignItems="center" position="relative" margin="1rem">
        <Avatar src={user.attachment?.url} alt={user.name} />
        <CustomAvatar src={idea.contributors[0].attachment?.url} alt={idea.contributors[0].attachment?.name} />
        <Box>
          <Name variant="subtitle2">
            {getFirstName(user.name)} {t('e')} {getFirstName(idea.contributors[0].name)}
          </Name>
          <Date variant="caption">
            <TimeAgo date={idea.timelineDate} formatter={formatterTimer(language)} />
          </Date>
        </Box>
        {idea.contributors.length > 1 && (
          <CustomTooltip title={t('ver_mais')} aria-label={t('ver_mais')}>
            <Participants onClick={handleViewParticipants}>
              +{idea.contributors.filter((item, index) => index !== 0).length}
            </Participants>
          </CustomTooltip>
        )}
      </Box>
    </>
  );
}