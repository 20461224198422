import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Grid,
} from "@material-ui/core"
import { 
  DialogAllUserFunctions,
  SelectedUser,
  ListFunction,
  FormEditFunction,
} from "./../../components"
import { userFunctionActions } from './../../actions';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
  select: {
    width: "100%",
    fontSize: 14,
  },
  label: {
    fontSize: 12,
    fontWeight: 600
  },
  container: {
    marginTop: 32
  },
  containerTitleWrapper: {
    display: "flex",
    alignItems: "center"
  },
  titleIcon: {
    width: 40,
    marginRight: 16,
    height: 40,
    borderRadius: "50%",
    backgroundColor: "theme.palette.primary.light",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  containerTitle: {
    fontWeight: 600
  },
  addMoreIconWrapper: {
    width: 18,
    height: 18,
    marginRight: 8,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main
  },
  addButton: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  trashButtonWrapper: {
    display: "flex",
    alignItems: "center"
  },
  card: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "2rem",
    boxShadow: "none",
  }
}));

export const UserFunctionPage = ({ id }) => {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const [editFunctions, setEditFunctions] = useState(false);
  const {
    loadingUser,
    userType,
    selectedUser,
  } = useSelector(state => state.userFunction)
  const { imagineLocals } = useSelector(state => state.domains)
  const { t } = useTranslation();

  useEffect(() => {
    if (!selectedUser) {
      if (id && !loadingUser) {
        dispatch(userFunctionActions.loadPage(id, imagineLocals));
      } else {
        if (!userType) {
          dispatch(userFunctionActions.getUserType())
        } else {
          dispatch(userFunctionActions.getAllUsers(userType))
        }
      }
    } else {
      dispatch(userFunctionActions.loadPage(selectedUser.id, imagineLocals));
    }
  }, [params, dispatch])

  function handleToggleEdit() {
    setEditFunctions(!editFunctions)
  }

  return (
    <>
      <DialogAllUserFunctions toggleEdit={handleToggleEdit} />
        <Grid container spacing={3} className={classes.container}>
          { selectedUser && (
            <Grid item xs={12} lg={9}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectedUser />
                    </Grid>
                  </Grid>
                </Grid>
                { selectedUser && (
                  <Grid item sm={12}>
                  { editFunctions ? (
                    <FormEditFunction />
                  ) : (
                    <ListFunction toggleEdit={handleToggleEdit} />
                  )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
    </>
  )
}
