import axios from 'axios';
import config from '../config';
import { requestHeaders, handleResponse } from '../helpers';

export const executionService = {
  executeIdea,
  getIdea
};

function executeIdea(execution, id) {
  return axios
    .post(
      `${config.URL_BASE}/ideas/${id}/execution`,
      execution,
      requestHeaders()
    )
    .then(handleResponse);
}
function getIdea(id) {
  return axios
    .get(`${config.URL_BASE}/ideas/${id}/execution/`, requestHeaders())
    .then(handleResponse);
}
