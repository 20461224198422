import { makeStyles } from "@material-ui/core"

const ideaEditStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    border: "1px solid #E7EAED",
    borderRadius: "8px",
    width: "100%",
    padding: "1.5rem !important",
    margin: 0,
  },
  sticky: {
    position: "sticky",
    top: "100px",
  },
  textStatus: {
    color: '#343A40',
    marginRight: '0.5rem',
  }, 
  status: {
    padding: '0.5rem',
    color: '#fff',
    textTransform: 'uppercase',
    borderRadius: '0.5rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatarIcon: {
    marginRight: 10,
    backgroundColor: theme.palette.primary.light,
  },
  info: {
    textTransform: 'lowercase',
    paddingTop: theme.spacing(5),
    color: "#999",
  },
  actionButton: {
    width: '100%',
  },
  linkTitle: {
    color: '#434343',
    paddingRight: 8,
    minWidth: 'fit-content'
  },
}))

export { ideaEditStyles }