import React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { ideaCreateActions, ideaUpdateActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.neutrals.low.dark,
  },
}));

function FileList({ files, isEdit=false }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function removeFile(id) {
    if (isEdit) {
    dispatch(ideaUpdateActions.removeFile(id));
    } else {
    dispatch(ideaCreateActions.removeFile(id));
    }
  }

  return (
    <Box className={classes.root}>
      {files.map((file) => (
        <Box className={classes.row} key={file.id}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <DescriptionIcon style={{ marginRight: 6 }} /> {file.name}
          </Box>
          <IconButton onClick={() => removeFile(file.id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
}

export { FileList };
