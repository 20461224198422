import React, { useEffect } from 'react'
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Avatar
} from "@material-ui/core"
import { ideaEditStyles } from './IdeaEditStyles'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import DeleteIcon from "@material-ui/icons/Delete"
import LinkIcon from '@material-ui/icons/Link';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { ideaUpdateActions, challengeActions } from "./../../actions"
import { ImagineLinkify } from "../TextLinkify";

export const IdeaEditGeneralLinks = () => {
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const { links } = useSelector(state => state.ideaUpdate);
  const { t } = useTranslation();

  function handleAddLink() {
    dispatch(ideaUpdateActions.addLink())
  }

  function handleRemoveLink(index) {
    return () => {
      dispatch(ideaUpdateActions.removeLink(index))
    }
  }

  function handleChangeLink(e, index) {
    dispatch(ideaUpdateActions.changeLink(e.target.value, index))
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Box display='flex' alignItems='center'>
            <Avatar className={classes.avatarIcon}>
              <LinkIcon color="primary" />
            </Avatar>
            <Typography variant="h3">{t('links')}</Typography>
          </Box>
          <Button 
            variant="text"
            color="primary"
            startIcon={<AddCircleOutlinedIcon />}
            onClick={handleAddLink}
          >
            {t('adicionar_link')}
          </Button>
        </Grid>
        { console.log(links)}
        { links.length > 0 && (
          <Grid item container xs={12} spacing={2}>
            {links.map((link, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle2" className={classes.linkTitle}>
                    {`Link ${index + 1}:`}
                  </Typography>
                  <TextField
                    variant="standard"
                    type="text"
                    fullWidth
                    name='link'
                    placeholder={t('insira_links_como_referencia_para_a_sua_ideia')}
                    value={link}
                    onChange={e => handleChangeLink(e, index)}
                  />
                  <IconButton onClick={handleRemoveLink(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
