import React, { useEffect } from 'react';
import { Container, Grid, makeStyles, Button, Box, Card } from '@material-ui/core';
import {
  BackNavAdmin,
  ChallengeCreateCard,
  ChallengeImageCard,
  ChallengeCriteriaCard,
  ChallengeDatesCard,
  ChallengeAwardCard,
} from '../../components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions } from '../../actions';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { history } from '../../helpers';
import { useTranslation } from 'react-i18next';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: 20,
  },
}));

function ChallengeEditPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    newChallenge: {
      imagesToRemove,
      name,
      description,
      goal,
      thumbnail,
      banner,
      requirement,
      startDate,
      dueDate,
      resultDate,
      rewards,
      _id,
    },
    newChallenge,
  } = useSelector((state) => state.challenge);

  const { t } = useTranslation();
  useEffect(() => {
    dispatch(challengeActions.getChallengeToEditById(id));

    return () => {
      dispatch(challengeActions.clearEdit());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function editChallenge() {
    imagesToRemove.forEach(type => dispatch(challengeActions.removeImagemServer(id, type)));
    dispatch(challengeActions.updateChallenge(newChallenge,t('seu_desafio_foi_editado')));
  }  
  function deleteChallenge() {
    dispatch(challengeActions.deleteChallenge(newChallenge));
  }

  return (
    <Box>
      <BackNavAdmin title={t('editar_desafio')}></BackNavAdmin>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.card}>
              {name && goal && description && (
                <ChallengeCreateCard    
                  name={name}
                  description={description}
                  goal={goal}
                ></ChallengeCreateCard>
              )}
            </Box>

            <Card className={classes.card}>
              <ChallengeImageCard  
                imagem={thumbnail}
                id={_id}
                type='thumbnail'
                isEdit={true}
              ></ChallengeImageCard>
              <ChallengeImageCard  
                imagem={banner}
                id={_id}
                type='banner'
                isEdit={true}
              ></ChallengeImageCard>
            </Card>

            <Box className={classes.card}>
              {requirement && (
                <ChallengeCriteriaCard    
                  requirement={requirement}
                ></ChallengeCriteriaCard>
              )}
            </Box>

            <Box className={classes.card}>
              {startDate && dueDate && resultDate && (
                <ChallengeDatesCard    
                  startDate={startDate}
                  dueDate={dueDate}
                  resultDate={resultDate}
                  isEditPage={true}
                ></ChallengeDatesCard>
              )}
            </Box>

            {config.SHOW_REWARD_FIELDS_ON_CHALLENGE && (
            <Box className={classes.card}>
                {rewards[0]?.description && <ChallengeAwardCard rewards={rewards} />}
              </Box>
              )}

            <Box
              className={classes.card}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button color="primary" onClick={() => history.goBack()}>
                {t('cancelar')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon style={{ color: 'white' }} />}
                onClick={deleteChallenge}
              >
                excluir desafio
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon style={{ color: 'white' }} />}
                onClick={editChallenge}
              >
                {t('salvar_desafio')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export { ChallengeEditPage };
